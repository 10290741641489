import Api from '@/services/Index';

const get = async (filename, str=null) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let config = str == null ? null : {responseType: 'arraybuffer'}
    return Api.get('/qr-verification/'+filename, config);
}



export default {
    get
}
